import React from 'react';
// import Typed from 'react-typed';

const Hero = () => {
  return (
    <div className='text-white p-10 text-left'>
      <div className="max-w-[800px] mt-[-96px]  w-full h-screen mx-auto text-left flex flex-col justify-center">
        <h1 className='md:text-7xl text-black sm:text-6xl text-4xl font-bold md:py-6'>
        Bienvenue sur le Site Officiel de Mouad Benmoussa
        </h1>
        {/* <div className='flex justify-center items-center'>
          <Typed
          className='md:text-5xl sm:text-4xl text-purple-500 text-xl font-bold md:pl-4 pl-2'
            strings={['Découvrez mon Périple vers la Richesse avec "Morocco Undercover Billionaire']}
            typeSpeed={120}
            backSpeed={140}
            loop
          />
        </div> */}
      </div>
    </div>
  );
};

export default Hero;
