import React from 'react';
import Laptop from '../assets/laptop.jpg';

const Analytics = () => {
  return (
    <div className='w-full bg-white py-16 px-4'>
    <h1 className='text-xl lg:text-4xl font-bold text-center hover:scale-105 duration-100'>Découvrez mon Périple vers la Richesse avec "Morocco Undercover Billionaire</h1>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[500px] mx-auto my-4' src={Laptop} alt='/' />
        <div className='flex flex-col justify-center'>
          <h1 className='text-purple-500 text-2xl font-bold '>   À Propos de Moi</h1>
          <p className="text-lg md:text-xl">
            Salut, je suis Mouad Benmoussa, entrepreneur passionné et auteur de plusieurs livres, dont :
            <h3 className="font-bold">- ETRE GENIAL EST VOTRE CHOIX</h3>
            <h3 className="font-bold">- BE DIFFERENT TO MAKE A DIFFERENCE</h3>
            <h3 className="font-bold">- MOROCCO UNDERCOVER BILLIONAIRE</h3>
            Bienvenue sur mon site personnel, où je partage mon parcours unique vers la création de richesse à partir de ressources modestes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
