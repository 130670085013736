import React from 'react';
import Analytics from './components/Analytics';
import Cards from './components/Cards';
import Footer from './components/Footer';
import Hero from './components/Hero';
import About from './components/About';
// import Navbar from './components/Navbar';
function App() {
  return (
    <div>
      {/* <Navbar /> */}
      <div className="bg-[url('./assets/bgg.jpg')] bg-cover bg-no-repeat">
      <Hero />
      </div>
      <Analytics />

      <div className='bg-purple-400'>
      <About />
      </div>
    
      <Cards />
      <Footer />
    </div>
  );
}

export default App;
