

const About = () => {
  return (
    <div className="w-full  py-16 px-4 shadow-lg rounded-lg">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8">
        <img className="w-full md:w-[500px] mx-auto my-4 rounded-lg" src="../assets/laptop.jpg" alt="/" />

        <div className="flex flex-col justify-center">
          <p className="text-black font-bold text-3xl mb-4">Mon Parcours</p>
          <p className="text-black font-bold text-base md:text-lg">
            Il y a quelques années, je me suis lancé dans un défi audacieux : transformer 20 000 DH en une entreprise prospère en seulement 6 mois. Sur ce site, je partage les étapes clés, les défis surmontés et les leçons apprises au cours de ce périple incroyable.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
