import React from 'react';
import { useSpring, animated, config } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import Single from '../assets/livre1.jpg';
import Double from '../assets/livre2.jpg';
import Triple from '../assets/triple.png';

const Card = ({ image, title, description, link }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const fadeIn = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(20px)',
    config: config.molasses,
  });

  return (
<animated.div
      ref={ref}
      style={fadeIn}
      className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 hover:bg-gray-100 duration-300"
    >      
    <img className='mx-auto h-60 w-72 mt-[-3rem] bg-white' src={image} alt="/" />
      <h2 className='text-2xl font-bold text-center p-8'>{title}</h2>
      <div className='text-center font-medium'>
        <p className='py-2 border-b mx-8 mt-2'>{description}</p>
      </div>
      <a href={link} className='bg-purple-400 hover:scale-105 duration-300 text-center w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>
        Voir Plus
      </a>
    </animated.div>
  );
};

const Cards = () => {
  return (
    <div className='w-full py-[10rem] px-4 bg-white'>
      <h1 className='text-6xl text-black font-semibold text-center mb-16'>Découvrez Mes Livres</h1>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8 '>
        <Card
          className=""
          image={Single}
          title='Etre génial est votre choix'
          description="De nature, l'homme recherche toujours la perfection, le succès. Il veut à tout prix se dépasser, changer sa vie, il veut une vie libre et heureuse, mais il est très souvent déçu par la réalité qui se présente en face."
          link='https://www.amazon.fr/Etre-g%C3%A9nial-est-votre-choix-ebook/dp/B08YCXPFF9/ref=sr_1_1?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=3APROI1O8NGSS&keywords=etre+genial+est+votre+choix&qid=1701856455&sprefix=etre+genial+est+votre+choix%2Caps%2C145&sr=8-1'
        />
        <Card
          image={Double}
          title='BE DIFFERENT'
          description="Money matters are bringing people to sleep their brain, most of them are uncomfortable when it comes to earn, saving, managing and investing money. This book is one of the rare tools that give strategies..."
          link='https://www.amazon.fr/BE-DIFFERENT-MAKE-DIFFERENCE-English-ebook/dp/B0B1XCLZN3/ref=sr_1_1?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=195Z6T1Q9UISA&keywords=Be+different+to+make+a+difference&qid=1701856586&sprefix=be+different+to+make+a+difference%2Caps%2C125&sr=8-1'
        />
        <Card
          image={Triple}
          title='BE DIFFERENT'
          description="Money matters are bringing people to sleep their brain, most of them are uncomfortable when it comes to earn, saving, managing and investing money. This book is one of the rare tools that give strategies..."
          link='#'
        />
      </div>
    </div>
  );
};

export default Cards;




// import React from 'react';
// import Single from '../assets/livre1.jpg'
// import Double from '../assets/livre2.jpg'
// import Triple from '../assets/triple.png'

// const Cards = () => {
//   return (
//     <div className='w-full py-[10rem] px-4 bg-white'>
//         <h1 className="text-6xl text-black font-semibold text-center mb-16">Découvrez Mes Livres</h1>
//       <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
//           <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
//               <img className='mx-auto h-60 w-72 mt-[-3rem] bg-white' src={Single} alt="/" />
//               <h2 className='text-2xl font-bold text-center p-8'>Etre génial est votre choix</h2>
//               <div className='text-center font-medium'>
//                   <p className='py-2 border-b mx-8 mt-2'>
//                   De nature, l'homme recherche toujours la perfection, le succès. Il veut à tout prix se dépasser, changer sa vie, il veut une vie libre et heureuse, mais il est très souvent déçu par la réalité qui se présente en face.
//                   </p>
//               </div>
//               <a  href="https://www.amazon.fr/Etre-g%C3%A9nial-est-votre-choix-ebook/dp/B08YCXPFF9/ref=sr_1_1?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=3APROI1O8NGSS&keywords=etre+genial+est+votre+choix&qid=1701856455&sprefix=etre+genial+est+votre+choix%2Caps%2C145&sr=8-1"
//                className='bg-purple-400 w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>Start Trial</a>
//           </div>


//           <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
//               <img className='mx-auto h-60 w-72 mt-[-3rem] bg-white' src={Double} alt="/" />
//               <h2 className='text-2xl font-bold text-center p-8'>BE DIFFERENT</h2>
//               <div className='text-center font-medium'>
//                   <p className='py-2 border-b mx-8 mt-2'>
//                   Money matters are bringing people to sleep their brain, most of them are uncomfortable when it comes to earn, saving, managing and investing money.
//                 This book is one of the rare tools that give strategies...
//                 </p>
//               </div>
//               <a  href="https://www.amazon.fr/BE-DIFFERENT-MAKE-DIFFERENCE-English-ebook/dp/B0B1XCLZN3/ref=sr_1_1?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=195Z6T1Q9UISA&keywords=Be+different+to+make+a+difference&qid=1701856586&sprefix=be+different+to+make+a+difference%2Caps%2C125&sr=8-1"
//               className='bg-purple-400 w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>Start Trial</a>
//           </div>


//           <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
//               <img className='mx-auto h-60 w-72 mt-[-3rem] bg-white' src={Double} alt="/" />
//               <h2 className='text-2xl font-bold text-center p-8'>BE DIFFERENT</h2>
//               <div className='text-center font-medium'>
//                   <p className='py-2 border-b mx-8 mt-2'>
//                   Money matters are bringing people to sleep their brain, most of them are uncomfortable when it comes to earn, saving, managing and investing money.
//                 This book is one of the rare tools that give strategies...
//                 </p>
//               </div>
//               <a className='bg-purple-400 w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>Start Trial</a>
//           </div>

//       </div>
//     </div>
//   );
// };

// export default Cards;
