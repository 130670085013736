import { Link } from "react-scroll";
import { AiTwotonePhone } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";

const Footer = () => {
  return (
    <div className=" bg-purple-400 p-5 text-black mt-4">
      <div className=" flex flex-col md:flex-row justify-between md:px-32 px-5 p-5">
        <div className=" w-full md:w-1/4">
          <Link to="/">
            <h1 className=" font-semibold text-center text-2xl text-black">Mouad Benmoussa</h1>
          </Link>
          <p className=" mt-4 text-center">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
          </p>
        </div>
        {/* <div>
          <h1 className=" font-medium text-xl text-black mt-4 underline">Addresse</h1>
          <p className=" mt-4">Riad Tetouane Tanger</p>
        </div> */}
        <div>
          <h1 className=" font-medium underline text-xl mt-4 text-black">Contact</h1>
          <div className=" flex flex-row items-center gap-2 mt-4">
            <AiTwotonePhone size={20} />
            <p className="">+212 762-181-592</p>
          </div>
          <div className=" flex flex-row items-center gap-2">
            <AiOutlineMail size={20} />
            <a href="mailto:mouadbenmoussa437@gmail.com">mouadbenmoussa437@gmail.com</a>
          </div>
        </div>
      </div>
      <div>
        <p className=" text-center mt-4">
          All
          rights reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
